import React from 'react';
import './landing.css';
import './games.css'
import { Blog } from './components/progress/blog';

const LandingPage = () => {
  const AboutUsRef = React.useRef<HTMLDivElement>(null);
  const ContactUsRef = React.useRef<HTMLDivElement>(null);

  return (
  <div className={"landing-page"}>

  <section className={"promo-0"} style={{backgroundImage: `url('images/promo.jpg')`}}>
  </section>

  <section className={"promo-1"}>
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <div style={{fontSize: 42, textShadow: `rgb(0, 0, 0) 5px 5px`}}>
        <span className="icon-fezuone-logo"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span><span className="path5"></span><span className="path6"></span><span className="path7"></span><span className="path8"></span><span className="path9"></span><span className="path10"></span><span className="path11"></span><span className="path12"></span><span className="path13"></span><span className="path14"></span></span>
      </div>
      <div style={{textShadow: `rgb(0, 0, 0) 2px 2px`, pointerEvents: 'none', fontSize: 16, fontFamily: `"DM Serif Text", sans-serif`}}>by Felipe Zuleta.</div>
    </div>
  </section>

  <Blog />

  <section className="promo-2" ref={AboutUsRef}>
    <div className="content-container">
      <h1>My Watchmaking Philosophy.</h1>
      <p>Driven by a deep curiosity. For me, watchmaking isn't just about telling time, but the merge between passion, design, precision and timelessness. 
      <p>
        It's all about bringing together passion, design, precision, and that timeless feel. It's about crafting something that feels both classic, fresh and built to last in every way.
      </p>
      </p>
    </div>
  </section>
  <section className="promo-3">
    <div className='photo'>
      <img src={`images/feli/feli.jpg`} alt='Felipe Zuleta' width={'100%'} height={'auto'} style={{borderRadius: 12}} />
    </div>
    <div className='descr'>
      <p>"After 10 years of being a classical animator, 10 years of software development, I'm venturing into the world of watches. Using latest tech for micro milling from NSCNC including the Elara, a Lathe, Mira, I'll be starting this venture. I'll soon be posting to my youtube channel, instagram and more (coming soon)" </p>
      <p>-- Felipe</p>
    </div>
  </section>
  <section className="contact-section" ref={ContactUsRef}>
    <div style={{fontSize: 72}}><span className="icon-fezuone-logo-v"><span className="path1"></span><span className="path2"></span><span className="path3"></span><span className="path4"></span></span></div>
    <div style={{ fontSize: 12, textAlign: 'center'}}>
      <div>info@fezuone.co</div>
      <div>Km 15 + 990 via las Palmas</div>
      <div>Envigado - Colombia</div>
    </div>
    <div style={{fontSize: 10,textAlign: 'center', marginTop: 32}}>
      <div>Copyright 2024 fezuone s.a.s</div>
    </div>
  </section>
</div>
  );
};

export default LandingPage;
