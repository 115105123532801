import React from 'react';
import './blog.css';
import moment from 'moment'

export const Blog = () => {
  return <section className='blog'>

  <BlogItem 
    title='Shrinking a bit the border'
    date='2024/09/07 11:15:00'
    content={<p>
      <p>Shrinking a bit strap metal border, so the watch feels a bit more round, I like it a bit more.</p>
      <div>
        <video controls autoPlay={true} width={'100%'} height={'auto'} loop={true} muted={true}>
          <source src="images/blog/2024-09-07/01.mp4" type="video/mp4"></source>
        </video>
      </div>
      </p>}
  />
  <BlogItem 
    title='Strap metal border to hide holes'
    date='2024/09/06 15:51:00'
    content={<p>
      <p>Working on the strap metal border.</p>
      <div>
        <video controls autoPlay={true} width={'100%'} height={'auto'} loop={true} muted={true}>
          <source src="images/blog/2024-09-06/01.mp4" type="video/mp4"></source>
        </video>
      </div>
      </p>}
  />
  <BlogItem 
    title='Strap holder thingy'
    date='2024/09/05 15:08:00'
    content={<p>
      <p>Working on the strap holder thingy, still missing the inside spring,</p>
      <p>Also added a little bit of a chamfer to the case, not sure if this is ok for the cnc yet.</p>
      <div>
        <video controls autoPlay={true} width={'100%'} height={'auto'} loop={true} muted={true}>
          <source src="images/blog/2024-09-05/01.mp4" type="video/mp4"></source>
        </video>
      </div>
      <div>
      <img src='images/blog/2024-09-05/01.jpg' alt='sketch' />
      <img src='images/blog/2024-09-05/02.jpg' alt='sketch' />
      </div>
      </p>}
  />
  <BlogItem 
      title='Hands'
      date='2024/09/04 15:08:00'
      content={<p>
        <p>Playing around with the Dial and hands, Im leaving a whole in the center to add the super-luminova.</p>
        <p>Tricky measurements with the micrometric extrudes, it's time to start thinking about the movement.</p>
        <video controls autoPlay={true} width={'100%'} height={'auto'} loop={true} muted={true}>
          <source src="images/blog/2024-09-04/01.mp4" type="video/mp4"></source>
        </video>
        </p>}
    />
  <BlogItem 
    title='Learning Fusion 360'
    date='2024/09/03 15:08:00'
    content={<p>
      <p>Total newb here, imported the svg into fusion 360, started playing around.</p>
      <p>Waiting for the NSCNC Elara, Mira and Lathe to start making some tests!</p>
      <video controls autoPlay={true} width={'100%'} height={'auto'} loop={true} muted={true}>
        <source src="images/blog/2024-09-03/reloj.mp4" type="video/mp4"></source>
      </video>
      </p>}
  />
  <BlogItem 
    title='Paper test'
    date='2024/08/27 06:00:00'
    content={<p>
      <p>Paper tests!.</p>
      <video controls autoPlay={false} width={'100%'} height={'auto'} loop={false} muted={false}>
        <source src="images/blog/2024-08-27/IMG_3732.MOV" type="video/mp4"></source>
      </video>
      </p>}
  />
  <BlogItem 
    title='Study Time'
    date='2024/08/26 06:00:00'
    content={<p>
      <p>Gonna need to level up.</p>
      <img src='images/blog/2024-08-26/01.jpg' alt='sketch' />
      </p>}
  />
  <BlogItem 
    title='Second Sketches'
    date='2024/08/25 10:40:00'
    content={<p>
      <p>The adventure continues in Adobe Illustrator.</p>
      <p>Here are some sketch followups (yeah It looks aweful, but let's keep on going, I can see a promise in the proportions and future):</p>
      <p>Im thinking, maybe have a seethrough dial, leather strap, and funky looking hands.</p>
      <img src='images/blog/2024-08-25/01.jpg' alt='sketch' />
      </p>}
  />
  <BlogItem 
    title='Total Newb'
    date='2024/08/24 14:40:00'
    content={<p>
      <p>The adventure begins in a sketching software Im confortable with, Adobe Illustrator.</p>
      <p>As I know nothing about manufacturing, I'll take it easier on myself and do a watch without any complications</p>
      <p>Here are some initial sketches:</p>
      <img src='images/blog/2024-08-24/01.jpg' alt='sketch' />
      </p>}
  />
  </section>
}
const BlogItem = (props: { title: string; date: string; content: React.JSX.Element}) => {
  return <div className='content'>
    <div className='top'>
      <h3>{props.title}</h3>
      <span>{moment(props.date).format('YYYY/MM/DD HH:mm')}</span>
    </div>
    <div className='body'>
      { props.content }
    </div>
  </div>
}